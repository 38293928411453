import { ProductCoverageModel } from 'api-hooks/product';
import { Expose, Type } from 'class-transformer';
import {
  ProductItemGradeEnum,
  ProductScaleEnum,
  SimSkinEnum,
  SimStatusEnum,
} from 'common/constants/enum';
import { selectLanguage } from 'hooks/use-language';

// Model
export class CartItemInvoiceableModel {
  id: string;
  name: string;
  type: string;
  description: string;
  scale: ProductScaleEnum;
  grade: ProductItemGradeEnum;

  @Expose({ name: 'product_id' })
  productId: string;

  @Expose({ name: 'is_available' })
  isAvailable: boolean;

  @Expose({ name: 'sell_currency_code' })
  sellCurrencyCode: string;

  @Expose({ name: 'external_name' })
  externalName: string;

  @Expose({ name: 'data_type' })
  dataType: string;

  @Expose({ name: 'area_code' })
  areaCode: string;

  @Expose({ name: 'sell_price' })
  @Type(() => Number)
  sellPrice: number;

  @Expose({ name: 'validity_days' })
  @Type(() => Number)
  validityDays: number;

  @Expose({ name: 'quota_in_gb' })
  @Type(() => Number)
  quotaInGb: number;

  @Expose({ name: 'total_countries' })
  @Type(() => Number)
  totalCountries: number;
}

export class CartItemSimModel {
  id: string;
  label: string;
  skin: SimSkinEnum;
  iccid: string;
  status: SimStatusEnum;

  @Expose({ name: 'assigned_at' })
  @Type(() => Date)
  assignedAt: Date;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;
}

export class CartItemModel {
  id: string;

  @Expose({ name: 'currency_code' })
  currencyCode: string;

  @Expose({ name: 'discounted_price' })
  discountedPrice: string;

  @Type(() => CartItemSimModel)
  sim: CartItemSimModel;

  @Type(() => Number)
  qty: number;

  @Type(() => Number)
  total: number;

  @Type(() => CartItemInvoiceableModel)
  invoiceable: CartItemInvoiceableModel;

  @Expose({ name: 'fx_price' })
  @Type(() => Number)
  fxPrice: number;

  @Expose({ name: 'fx_rate' })
  @Type(() => Number)
  fxRate: number;

  @Expose({ name: 'activation_at' })
  @Type(() => Date)
  activationAt: Date | null;

  @Expose({ name: 'sell_price' })
  @Type(() => Number)
  sellPrice: number;
}

export class CartModel {
  id: string;

  @Expose({ name: 'can_checkout' })
  canCheckout: boolean;

  @Type(() => Number)
  total: number;

  @Type(() => CartItemModel)
  items: CartItemModel[];
}

export class CartItemDetailDetailModel {
  text: string;
  value: boolean;
}

export class CartItemDetailModel {
  id: string;

  get name() {
    return selectLanguage({
      en: this.nameEn,
      id: this.nameId,
    });
  }

  @Expose({ name: 'name' })
  nameEn: string;

  @Expose({ name: 'name_id' })
  nameId: string;

  scale: ProductScaleEnum;
  grade: ProductItemGradeEnum;

  @Expose({ name: 'product_item_id' })
  productItemId: string;

  @Expose({ name: 'area_code' })
  areaCode: string;

  @Expose({ name: 'product_id' })
  productId: string;

  @Expose({ name: 'currency_code' })
  currencyCode: string;

  @Expose({ name: 'discounted_price' })
  discountedPrice: string;

  @Type(() => CartItemSimModel)
  sim: CartItemSimModel;

  @Type(() => Number)
  qty: number;

  @Type(() => Number)
  total: number;

  @Type(() => CartItemDetailDetailModel)
  details: CartItemDetailDetailModel[];

  @Expose({ name: 'total_countries' })
  @Type(() => Number)
  totalCountries: number;

  @Expose({ name: 'quota_in_gb' })
  @Type(() => Number)
  quotaInGb: number;

  @Expose({ name: 'validity_days' })
  @Type(() => Number)
  validityDays: number;

  @Expose({ name: 'fx_price' })
  @Type(() => Number)
  fxPrice: number;

  @Expose({ name: 'fx_rate' })
  @Type(() => Number)
  fxRate: number;

  @Expose({ name: 'activation_at' })
  @Type(() => Date)
  activationAt: Date;

  @Expose({ name: 'sell_price' })
  @Type(() => Number)
  sellPrice: number;

  @Type(() => ProductCoverageModel)
  coverages: ProductCoverageModel[];
}

// Input
export type getCartItemDetailInput = {
  cartItemId: string;
};

export type AddToCartInput = {
  body: {
    productItemId?: string;
    qty: number;
    simIccid?: string;
    activationAt: Date | null;
  };
};

export type EditCartItemInput = {
  id: string;
  productItemId: string;
  qty: number;
  activationAt: Date | null;
};

export type EditCartInput = {
  body: {
    items: EditCartItemInput[];
  };
};

export type DeleteCartItemInput = {
  body: {
    itemIds: string[];
  };
};
