import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import { IdInput, StaleTimes } from 'api-hooks/common';
import {
  MutationFetchFunction,
  QueryFetchFunction,
  QueryTransformer,
} from 'common/api/fetch';
import { ApiQueryFunction } from 'common/api/fetch-types';
import { ApiError, ApiResult, ExtendedApiResult } from 'common/api/model';

import {
  PaymentFlowApiResult,
  SaleInvoiceBillCartInput,
  SaleInvoiceBillInput,
  SaleInvoiceBillModel,
  SaleInvoiceLiteModel,
  SaleInvoiceModel,
  SaleInvoicePreparePaymentInput,
  SaleInvoicePreparePaymentModel,
  SaleInvoicePromoModel,
  SaleInvoiceShowPendingPaymentModel,
  SaleInvoiceShowStatusModel,
  SimPlanProgressModel,
  getSaleInvoiceInput,
  getSaleInvoicePromoInput,
  getSaleInvoicesInput,
  getShowPendingPaymentInput,
  getShowStatusInput,
} from './model';

export const saleInvoiceKey = {
  listKey: 'getSaleInvoices',
  detailKey: 'getSaleInvoice',
  promoKey: 'getSaleInvoicePromo',
  showPendingKey: 'getShowPendingPayment',
  showStatusKey: 'getShowStatus',
  simPlanProgressKey: 'simPlanProgressKey',
  billKey: 'getInvoiceBill',
  billCartKey: 'getInvoiceBillCart',
  preparePaymentKey: 'getPreparePayment',
  list(input?: getSaleInvoicesInput) {
    return [saleInvoiceKey.listKey, input?.params].filter(Boolean);
  },
  detail(input?: getSaleInvoiceInput) {
    return [saleInvoiceKey.detailKey, input?.id];
  },
  promo(input?: getSaleInvoicePromoInput) {
    return [saleInvoiceKey.promoKey, input?.params];
  },
  showPending(input?: getShowPendingPaymentInput) {
    return [saleInvoiceKey.showPendingKey, input?.saleInvoiceId];
  },
  showStatus(input?: getShowStatusInput) {
    return [saleInvoiceKey.showStatusKey, input?.saleInvoiceId];
  },
  simPlanProgress(input?: IdInput) {
    return [saleInvoiceKey.simPlanProgressKey, input?.id];
  },
  bill(input?: SaleInvoiceBillInput) {
    return [saleInvoiceKey.billKey, input];
  },
  billCart(input?: SaleInvoiceBillCartInput) {
    return [saleInvoiceKey.billCartKey, input];
  },
  preparePayment(input?: SaleInvoicePreparePaymentInput) {
    return [
      saleInvoiceKey.preparePaymentKey,
      input?.saleInvoiceId,
      input?.body,
    ];
  },
};

export function useGetSaleInvoices(
  input?: getSaleInvoicesInput,
  options?: UseInfiniteQueryOptions<
    ExtendedApiResult<SaleInvoiceLiteModel[]>,
    ApiError
  >,
): UseInfiniteQueryResult<ExtendedApiResult<SaleInvoiceLiteModel[]>, ApiError> {
  return QueryTransformer(
    useInfiniteQuery(
      saleInvoiceKey.list(input),
      ({ pageParam = 1 }) =>
        QueryFetchFunction({
          url: 'sale-invoices',
          params: {
            ...input?.params,
            limit: 15,
            page: pageParam,
          },
        }),
      {
        staleTime: StaleTimes.Short,
        ...options,
      },
    ),
    SaleInvoiceLiteModel,
  );
}

export function useGetSaleInvoice(
  input?: getSaleInvoiceInput,
  options?: UseQueryOptions<ApiResult<SaleInvoiceModel>, ApiError>,
): UseQueryResult<ApiResult<SaleInvoiceModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      saleInvoiceKey.detail(input),
      () => QueryFetchFunction({ url: `sale-invoices/${input?.id}` }),
      {
        // staleTime: StaleTimes.Medium,
        ...options,
      },
    ),
    SaleInvoiceModel,
  );
}

export function useGetSaleInvoicePromo(
  input?: getSaleInvoicePromoInput,
  options?: UseQueryOptions<ApiResult<SaleInvoicePromoModel[]>, ApiError>,
): UseQueryResult<ApiResult<SaleInvoicePromoModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      saleInvoiceKey.promo(input),
      () =>
        QueryFetchFunction({
          url: 'sale-invoices/promo-codes',
          params: input?.params,
        }),
      {
        staleTime: StaleTimes.Medium,
        ...options,
      },
    ),
    SaleInvoicePromoModel,
  );
}

/** Fetches billing data from backend. */
export const useSaleInvoiceBill: ApiQueryFunction<
  SaleInvoiceBillInput,
  ApiResult<SaleInvoiceBillModel>
> = function (input, options) {
  return useQuery(
    saleInvoiceKey.bill(input),
    async function () {
      return await MutationFetchFunction({
        url: 'sale-invoices/bill',
        method: 'POST',
        body: input,
        classType: SaleInvoiceBillModel,
      });
    },
    options,
  );
};

export const useSaleInvoiceBillCart: ApiQueryFunction<
  SaleInvoiceBillCartInput,
  ApiResult<SaleInvoiceBillModel>
> = function (input, options) {
  return useQuery(
    saleInvoiceKey.billCart(input),
    async function () {
      return await MutationFetchFunction({
        url: 'sale-invoices/bill-cart',
        method: 'POST',
        body: input,
        classType: SaleInvoiceBillModel,
      });
    },
    options,
  );
};

export const usePreparePayment: ApiQueryFunction<
  SaleInvoicePreparePaymentInput,
  PaymentFlowApiResult<SaleInvoicePreparePaymentModel>
> = function (input, options) {
  return useQuery(
    saleInvoiceKey.preparePayment(input),
    async function () {
      return await MutationFetchFunction({
        url: `sale-invoices/${input.saleInvoiceId}/prepare-payment`,
        method: 'POST',
        body: input.body,
        classType: SaleInvoicePreparePaymentModel,
      });
    },
    options,
  );
};

export function useGetShowPendingPayment(
  input?: getShowPendingPaymentInput,
  options?: UseQueryOptions<
    PaymentFlowApiResult<SaleInvoiceShowPendingPaymentModel>,
    ApiError
  >,
): UseQueryResult<
  PaymentFlowApiResult<SaleInvoiceShowPendingPaymentModel>,
  ApiError
> {
  return QueryTransformer(
    useQuery(
      saleInvoiceKey.showPending(input),
      () => {
        return QueryFetchFunction({
          url: `sale-invoices/${input?.saleInvoiceId}/show-pending-payment`,
        });
      },
      options,
    ),
    SaleInvoiceShowPendingPaymentModel,
  );
}

export function useGetShowStatus(
  input?: getShowStatusInput,
  options?: UseQueryOptions<ApiResult<SaleInvoiceShowStatusModel>, ApiError>,
): UseQueryResult<ApiResult<SaleInvoiceShowStatusModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      saleInvoiceKey.showStatus(input),
      () =>
        QueryFetchFunction({
          url: `sale-invoices/${input?.saleInvoiceId}/status`,
        }),
      options,
    ),
    SaleInvoiceShowStatusModel,
  );
}

export const useGetSimPlanProgress: ApiQueryFunction<
  IdInput,
  ApiResult<SimPlanProgressModel>
> = function (input, options) {
  return QueryTransformer(
    useQuery(
      saleInvoiceKey.simPlanProgress(input),
      () =>
        QueryFetchFunction({
          url: `sale-invoices/${input.id}/sim-plan-progress`,
        }),
      options,
    ),
    SimPlanProgressModel,
  );
};
