import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { cartKey } from 'api-hooks/cart';
import { IdInput } from 'api-hooks/common';
import { notificationKey } from 'api-hooks/notification/query';
import { MutationFetchFunction } from 'common/api/fetch';
import { ApiError, ApiResult, MessageResult } from 'common/api/model';
import { queryClient } from 'common/api/query-client';

import {
  PaymentFlowApiResult,
  SaleInvoiceCheckoutCartInput,
  SaleInvoiceCheckoutInput,
  SaleInvoiceCheckoutModel,
  SaleInvoiceDownloadPdfInput,
  SaleInvoiceDownloadPdfModel,
  SaleInvoiceProcessPendingPaymentInput,
  SaleInvoiceSettlePaymentInput,
  SaleInvoiceSettlePaymentModel,
  SaleInvoiceShowPendingPaymentModel,
} from './model';
import { saleInvoiceKey } from './query';

export function useSaleInvoiceCheckout(
  options?: UseMutationOptions<
    ApiResult<SaleInvoiceCheckoutModel>,
    ApiError,
    SaleInvoiceCheckoutInput
  >,
): UseMutationResult<
  ApiResult<SaleInvoiceCheckoutModel>,
  ApiError,
  SaleInvoiceCheckoutInput
> {
  return useMutation<
    ApiResult<SaleInvoiceCheckoutModel>,
    ApiError,
    SaleInvoiceCheckoutInput
  >(
    async function (body) {
      return await MutationFetchFunction({
        url: 'sale-invoices/checkout',
        method: 'POST',
        body: body.body,
        classType: SaleInvoiceCheckoutModel,
      });
    },
    {
      onSuccess(data, variables, context) {
        setTimeout(() => {
          queryClient.removeQueries([saleInvoiceKey.billKey]);
        }, 15 * 1000);
      },
      ...options,
    },
  );
}

export function useSaleInvoiceCheckoutCart(
  options?: UseMutationOptions<
    ApiResult<SaleInvoiceCheckoutModel>,
    ApiError,
    SaleInvoiceCheckoutCartInput
  >,
): UseMutationResult<
  ApiResult<SaleInvoiceCheckoutModel>,
  ApiError,
  SaleInvoiceCheckoutCartInput
> {
  return useMutation<
    ApiResult<SaleInvoiceCheckoutModel>,
    ApiError,
    SaleInvoiceCheckoutCartInput
  >(
    async function (body) {
      return await MutationFetchFunction({
        url: 'sale-invoices/checkout-cart',
        method: 'POST',
        body: body.body,
        classType: SaleInvoiceCheckoutModel,
      });
    },
    {
      onSuccess(data, variables, context) {
        setTimeout(() => {
          queryClient.removeQueries([saleInvoiceKey.billCartKey]);
        }, 15 * 1000);
        queryClient.invalidateQueries([
          saleInvoiceKey.preparePaymentKey,
          data.data.saleInvoiceId,
        ]);
        queryClient.invalidateQueries([cartKey.getCartKey]);
        queryClient.invalidateQueries([notificationKey.badgesKey]);
        queryClient.invalidateQueries(saleInvoiceKey.list());
      },
      ...options,
    },
  );
}

export function useSaleInvoiceCancel(
  options?: UseMutationOptions<MessageResult, ApiError, IdInput>,
): UseMutationResult<MessageResult, ApiError, IdInput> {
  return useMutation<MessageResult, ApiError, IdInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `sale-invoices/${body?.id}/cancel`,
        method: 'POST',
      });
    },
    {
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries(saleInvoiceKey.list());
        queryClient.invalidateQueries(
          saleInvoiceKey.detail({
            id: variables.id,
          }),
        );
        queryClient.invalidateQueries(
          saleInvoiceKey.showStatus({
            saleInvoiceId: variables.id,
          }),
        );
        queryClient.invalidateQueries([saleInvoiceKey.listKey]);
      },
      ...options,
    },
  );
}

export function useSaleInvoiceDownloadPdf(
  options?: UseMutationOptions<
    ApiResult<SaleInvoiceDownloadPdfModel>,
    ApiError,
    SaleInvoiceDownloadPdfInput
  >,
): UseMutationResult<
  ApiResult<SaleInvoiceDownloadPdfModel>,
  ApiError,
  SaleInvoiceDownloadPdfInput
> {
  return useMutation<
    ApiResult<SaleInvoiceDownloadPdfModel>,
    ApiError,
    SaleInvoiceDownloadPdfInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `sale-invoices/${body?.saleInvoiceId}/pdf`,
      method: 'POST',
    });
  }, options);
}

export function useSettlePayment(
  options?: UseMutationOptions<
    PaymentFlowApiResult<SaleInvoiceSettlePaymentModel>,
    ApiError,
    SaleInvoiceSettlePaymentInput
  >,
): UseMutationResult<
  PaymentFlowApiResult<SaleInvoiceSettlePaymentModel>,
  ApiError,
  SaleInvoiceSettlePaymentInput
> {
  return useMutation<
    PaymentFlowApiResult<SaleInvoiceSettlePaymentModel>,
    ApiError,
    SaleInvoiceSettlePaymentInput
  >(
    async function (body) {
      return await MutationFetchFunction({
        url: `sale-invoices/${body?.saleInvoiceId}/settle-payment`,
        method: 'POST',
        body: body?.body,
        classType: SaleInvoiceSettlePaymentModel,
      });
    },
    {
      onSuccess(data, variables, context) {
        setTimeout(() => {
          queryClient.removeQueries([
            saleInvoiceKey.preparePaymentKey,
            variables.saleInvoiceId,
          ]);
        }, 15 * 1000);
        queryClient.invalidateQueries(saleInvoiceKey.list());
        queryClient.invalidateQueries(
          saleInvoiceKey.detail({
            id: variables.saleInvoiceId,
          }),
        );
      },
      ...options,
    },
  );
}

export function cleanupQueryClientAfterProcessPendingPayment(id: string) {
  setTimeout(() => {
    queryClient.removeQueries([saleInvoiceKey.showPendingKey, id]);
  }, 10000);
  queryClient.invalidateQueries(saleInvoiceKey.list());
  queryClient.invalidateQueries(
    saleInvoiceKey.detail({
      id,
    }),
  );
}

export function useProcessPendingPayment(
  options?: UseMutationOptions<
    ApiResult<SaleInvoiceShowPendingPaymentModel>,
    ApiError,
    SaleInvoiceProcessPendingPaymentInput
  >,
): UseMutationResult<
  ApiResult<SaleInvoiceShowPendingPaymentModel>,
  ApiError,
  SaleInvoiceProcessPendingPaymentInput
> {
  return useMutation<
    ApiResult<SaleInvoiceShowPendingPaymentModel>,
    ApiError,
    SaleInvoiceProcessPendingPaymentInput
  >(
    async function (body) {
      return await MutationFetchFunction({
        url: `sale-invoices/${body?.saleInvoiceId}/process-pending-payment`,
        method: 'POST',
        body: body?.body,
        classType: SaleInvoiceShowPendingPaymentModel,
      });
    },
    {
      onSuccess(data, variables, context) {
        cleanupQueryClientAfterProcessPendingPayment(variables.saleInvoiceId);
      },
      ...options,
    },
  );
}
